<template>
  <div v-if="isLoading" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white p-6 rounded-lg shadow-xl flex flex-col items-center">
      <div class="animate-spin h-12 w-12 border-4 border-teal-500 rounded-full border-t-transparent mb-4"></div>
      <p class="text-gray-700 text-lg">載入中...</p>
    </div>
  </div>

  <div
    class="bg-white p-5 sm:p-8 relative rounded-lg shadow-lg max-w-full sm:max-w-4xl overflow-y-auto"
    :class="{ 
      'mx-4 my-6 sm:mx-auto sm:my-4 max-h-[90vh] overflow-y-auto': isMobile, 
      'mx-auto max-h-[80vh] overflow-y-auto': !isMobile,
      'opacity-50': isLoading 
    }"
    :style="{
      'scrollbar-width': 'thin',
      'scrollbar-color': '#CBD5E0 transparent',
      'overflow-y': 'auto'
    }"
  >
    <div class="text-left">
      <h4 class="text-2xl sm:text-3xl font-bold mb-6 sm:mb-8 text-teal-600">
        課程推薦表單 / Course Recommendation Form
      </h4>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
        <div class="form-group">
          <label class="block mb-2 text-base font-medium text-gray-700"
            ><span class="text-red-500">*</span>電子郵件 / E-mail</label
          >
          <InputText
            v-model="formData.email"
            class="w-full h-12 px-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent transition"
            placeholder="請輸入您的電子郵箱"
          />
        </div>
        <div class="form-group">
          <label class="block mb-2 text-base font-medium text-gray-700"
            ><span class="text-red-500">*</span>聯絡電話 / Contact Number</label
          >
          <InputText
            v-model="formData.phone"
            class="w-full h-12 px-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 focus:border-transparent transition"
            placeholder="請輸入您的聯絡電話"
          />
        </div>
      </div>

      <div class="form-group mt-4 sm:mt-6">
        <label class="block mb-2 text-base font-medium text-gray-700"
          >推薦課程修課學年期 / Recommended Course Semester</label
        >
        <div class="relative">
          <Dropdown
            v-model="formData.semester"
            :options="semesterOptions"
            optionLabel="name"
            placeholder="請選擇修課學期"
            class="w-full h-12 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 transition"
            :class="{ 'p-inputtext-sm': isMobile }"
            :appendTo="isMobile ? 'body' : undefined"
            @change="updateCourseOptions"
            :disabled="isLoading"
          />
          <div v-if="isLoading" class="absolute inset-y-0 right-10 flex items-center">
            <div class="animate-spin h-5 w-5 border-2 border-teal-500 rounded-full border-t-transparent"></div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:mt-4">
        <div class="form-group">
          <label class="block mb-1 text-xs sm:text-sm font-medium text-gray-700"
            >推薦課程名稱 / Recommended Course Name</label
          >
          <Dropdown
            v-model="formData.courseName"
            :options="filteredCourseNames"
            optionLabel="name"
            placeholder="請選擇課程名稱"
            class="w-full"
            @change="updateCourseDetails"
          />
        </div>
        <div class="form-group">
          <label class="block mb-1 text-xs sm:text-sm font-medium text-gray-700"
            >開課代碼 / Course Code</label
          >
          <InputText
            v-model="formData.courseCode"
            class="w-full p-2 sm:p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 transition"
            placeholder="開課代碼"
            disabled
          />
        </div>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:mt-4">
        <div class="form-group">
          <label class="block mb-1 text-xs sm:text-sm font-medium text-gray-700"
            >推薦課程開課單位 / Recommended Department</label
          >
          <InputText
            v-model="formData.teaching_unit"
            class="w-full p-2 sm:p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 transition"
            placeholder="推薦課程開課單位"
            disabled
          />
        </div>
        <div class="form-group">
          <label class="block mb-1 text-xs sm:text-sm font-medium text-gray-700"
            >推薦課程開課教師 / Recommended Course Teacher</label
          >
          <InputText
            v-model="formData.teacher"
            class="w-full p-2 sm:p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 transition"
            placeholder="推薦課程開課教師"
            disabled
          />
        </div>
      </div>

      <div class="form-group mt-4 sm:mt-6">
        <label class="block mb-2 text-base font-medium text-gray-700"
          ><span class="text-red-500">*</span>心得 / Feedback</label
        >
        <Textarea
          v-model="formData.feedback"
          class="w-full p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-teal-500 transition"
          rows="3"
          placeholder="請分享您的課程心得..."
          @input="updateCharCount"
          :disabled="!canEnterFeedback"
        />
        <div class="text-right text-xs sm:text-sm text-gray-500 mt-1">
          <span :class="{ 'text-red-500': charCount >= 500 }">
            字數統計: {{ charCount }} / 500
          </span>
        </div>
      </div>

      <button
        class="w-full h-12 text-white text-lg font-medium mt-8 rounded-lg transition-all duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
        style="background-color: #2db3b3"
        @click="submitForm"
      >
        <span
          class="inline-block transition-all duration-300 ease-in-out transform group-hover:scale-105"
        >
          提交推薦 / Submit Recommendation
        </span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick, watch, defineEmits } from "vue";
import axios from "axios";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import debounce from "lodash/debounce";
import { ElNotification } from "element-plus";
import { closeModal } from "jenesius-vue-modal";

const emit = defineEmits(["formSubmitted"]);

const data = ref([]);
const formData = ref({
  email: "",
  phone: "",
  semester: null,
  courseName: null,
  courseCode: "",
  feedback: "",
  teaching_unit: "",
  teacher: "",
});
const filteredCourseNames = ref([]);
const isSubmitting = ref(false);
const isMobile = ref(false);

// Add new ref for loading state
const isLoading = ref(true);

// Modify fetchStudentCourses function
const fetchStudentCourses = async () => {
  isLoading.value = true;
  try {
    const stuInfo = JSON.parse(sessionStorage.getItem("stuInfo"));
    const response = await axios.get(
      `https://selflearning1.cycu.edu.tw/api/getdata/studentCourse?stuId=${stuInfo.userId}`
    );
    // 過濾掉 dept_code 為 null 的數據
    data.value = response.data.filter(item => item.dept_code !== undefined);
    console.log('Filtered data:', data.value);
  } catch (error) {
    console.error("獲取學生課程數據失敗:", error);
    ElNotification({
      title: "錯誤",
      message: "獲取學生課程數據失敗，請稍後重試",
      type: "error",
    });
  } finally {
    isLoading.value = false;
  }
};

const semesterOptions = computed(() => {
  const uniqueSemesters = [
    ...new Set(data.value.map((item) => item.pass_yearterm)),
  ];
  return uniqueSemesters
    .sort((a, b) => a - b) // 由小到大排序（最舊學期在前）
    .map((semester) => ({
      name: semester,
      value: semester,
    }));
});

const updateCourseOptions = () => {
  const selectedSemester = formData.value.semester;
  if (selectedSemester) {
    const coursesForSemester = data.value.filter(
      (item) => item.pass_yearterm === selectedSemester.value
    );

    const uniqueCourses = new Map();
    coursesForSemester.forEach((course) => {
      if (!uniqueCourses.has(course.op_code_a)) {
        uniqueCourses.set(course.op_code_a, {
          name: course.curs_nm_c_s_a,
          value: course,
          code: course.op_code_a,
          teaching_unit: course.dept_abvi_c,
          teacher: course.tch_name.dataList?.[0]?.cname || "N/A",
        });
      }
    });

    filteredCourseNames.value = Array.from(uniqueCourses.values());
  } else {
    filteredCourseNames.value = [];
  }

  // Reset form fields
  formData.value.courseName = null;
  formData.value.courseCode = "";
  formData.value.teaching_unit = "";
  formData.value.teacher = "";
};

const updateCourseDetails = () => {
  const selectedCourse = formData.value.courseName;
  if (selectedCourse) {
    const { code, teaching_unit, teacher } = selectedCourse;
    formData.value.courseCode = code;
    formData.value.teaching_unit = teaching_unit;
    formData.value.teacher = teacher;
  } else {
    formData.value.courseCode = "";
    formData.value.teaching_unit = "";
    formData.value.teacher = "";
  }
};

const charCount = computed(() => formData.value.feedback.length);

const updateCharCount = () => {
  if (formData.value.feedback.length > 500) {
    formData.value.feedback = formData.value.feedback.slice(0, 500);
  }
};

const canEnterFeedback = computed(
  () => formData.value.semester && formData.value.courseName
);

const submitForm = debounce(async () => {
  if (isSubmitting.value) {
    console.log("Form submission in progress. Please wait.");
    return;
  }

  // 添加验证逻辑
  if (!validateForm()) {
    return;
  }

  isSubmitting.value = true;

  try {
    const stuInfo = JSON.parse(sessionStorage.getItem("stuInfo"));
    if (!stuInfo) {
      throw new Error("無法獲取學生信息");
    }

    const currentDate = new Date().toISOString().slice(0, 19).replace("T", " ");

    const dataToSend = {
      id: 0,
      student_name: stuInfo.userName,
      student_id: stuInfo.userId,
      department: stuInfo.userDept,
      submission_time: currentDate,
      review_status: "待審核",
      email: formData.value.email,
      phone: formData.value.phone,
      recommended_semester: formData.value.semester?.value,
      recommended_course_name: formData.value.courseName?.name,
      teaching_unit: formData.value.teaching_unit,
      teacher: formData.value.teacher,
      course_code: formData.value.courseCode,
      feedback: formData.value.feedback,
      count: 0,
      send_mail: 0,
      created_at: currentDate,
      updated_at: currentDate,
    };

    const response = await axios.post(
      "/feedback/course-recommendation",
      dataToSend
    );

    if (response.data.status === true) {
      ElNotification({
        title: "成功",
        message: "您的課程推薦已成功提交！",
        type: "success",
      });

      dataToSend.id = response.data.insert_id;

      // Emit event with the newly submitted data
      emit("formSubmitted", dataToSend);

      // Reset form
      Object.keys(formData.value).forEach((key) => {
        formData.value[key] = "";
      });
      formData.value.semester = null;
      formData.value.courseName = null;

      updateCourseOptions();

      nextTick(() => {
        const formElement = document.querySelector("form");
        if (formElement) {
          formElement.style.opacity = "0";
          formElement.style.transition = "opacity 0.5s ease";

          setTimeout(() => {
            formElement.style.opacity = "1";
            window.scrollTo({ top: 0, behavior: "smooth" });
          }, 100);
        }
      });

      closeModal();
    } else {
      throw new Error(response.data.message || "提交失敗");
    }
  } catch (error) {
    console.error("提交失敗:", error);
    ElNotification({
      title: "錯誤",
      message: error.message || "提交失敗,請稍後再試",
      type: "error",
    });
  } finally {
    isSubmitting.value = false;
  }
}, 300);

// 添加新的验证函数
const validateForm = () => {
  const phoneRegex = /^\d{10}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!phoneRegex.test(formData.value.phone)) {
    ElNotification({
      title: "錯誤",
      message: "聯絡電話必須是手機號碼",
      type: "error",
    });
    return false;
  }

  if (!emailRegex.test(formData.value.email)) {
    ElNotification({
      title: "錯誤",
      message: "請輸入有效的電子郵件",
      type: "error",
    });
    return false;
  }

  return true;
};

onMounted(() => {
  fetchStudentCourses();
  const checkMobile = () => {
    isMobile.value = window.innerWidth < 768;
  };
  checkMobile();
  window.addEventListener("resize", checkMobile);
});

watch(() => formData.value.semester, updateCourseOptions);
watch(() => formData.value.courseName, updateCourseDetails);
</script>

<style scoped>
@media (max-width: 640px) {
  .form-group {
    margin-bottom: 1rem;
    padding: 0 0.5rem;
  }
  
  /* Add more padding to dropdown panels so they're easier to tap */
  :deep(.p-dropdown-panel) {
    padding: 0.5rem 0;
  }
  
  :deep(.p-dropdown-panel .p-dropdown-items .p-dropdown-item) {
    padding: 0.75rem 1rem;
  }
  
  /* Increase spacing between form sections */
  .grid {
    margin-bottom: 1rem;
  }
}

.hover-animate {
  position: relative;
  overflow: hidden;
}

.hover-animate::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
}

.hover-animate:hover::after {
  transform: translateY(0);
}

:deep(.p-dropdown) {
  height: 48px;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
}

:deep(.p-dropdown-label) {
  padding: 0 1rem !important;
  display: flex;
  align-items: center;
  height: 100%;
}

:deep(.p-inputtext) {
  height: 48px;
  line-height: 48px;
  padding: 0.75rem 1rem !important;
  border-radius: 0.5rem;
}

:deep(.p-dropdown-trigger) {
  width: 42px;
}

:deep(.p-dropdown-panel .p-dropdown-items .p-dropdown-item) {
  padding: 0.75rem 1rem;
}

:deep(.p-textarea) {
  border-radius: 0.5rem;
  min-height: 120px !important;
}

:deep(.p-inputtextarea) {
  font-size: 1rem !important;
  line-height: 1.5 !important;
  padding: 1rem !important;
  height: auto !important;
  resize: vertical;
}

/* Add these styles */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

:deep(.p-dropdown-label.p-placeholder) {
  margin-top: 0;
}

/* Add these new styles */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #CBD5E0;
  border-radius: 3px;
}

@media (max-width: 640px) {
  /* Existing mobile styles... */
  
  /* Add smooth scrolling for mobile */
  ::-webkit-scrollbar {
    width: 4px;
  }
}

/* 修改滾動條樣式 */
:deep(.p-dropdown-panel) {
  max-height: 200px;
  overflow-y: auto;
}

/* 確保主容器可以滾動 */
.bg-white {
  height: auto;
  min-height: 95vh;
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch; /* 為 iOS 添加平滑滾動 */
}

/* 為移動設備優化滾動 */
@media (max-width: 640px) {
  .bg-white {
    max-height: 90vh !important;
    overflow-y: scroll !important;
  }
}

/* 优化滚动条样式 */
.bg-white {
  scrollbar-width: thin;
  scrollbar-color: #CBD5E0 transparent;
}

.bg-white::-webkit-scrollbar {
  width: 6px;
}

.bg-white::-webkit-scrollbar-track {
  background: transparent;
}

.bg-white::-webkit-scrollbar-thumb {
  background-color: #CBD5E0;
  border-radius: 3px;
}

/* 确保内容区域有足够的内边距，避免内容被滚动条遮挡 */
.bg-white > div {
  padding-right: 6px;
}
</style>

